<template>
  <div>
    <PageDynamicBreadcrumb />

    <PageHeader title="Offer Creator" />

    <OfferForm
      type="create"
      :submitting="submitting"
      @submit="createOffer"
      :template-offer-id="templateOfferId"
    />
  </div>
</template>

<script>
import OfferForm from "@/components/OfferForm";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";

export default {
  name: "OfferCreator",
  components: {
    PageDynamicBreadcrumb,
    PageHeader,
    OfferForm
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    templateOfferId() {
      return typeof this.$route.params.templateOfferId !== "undefined"
        ? this.$route.params.templateOfferId
        : "";
    }
  },
  mounted() {},
  methods: {
    createOffer(offer) {
      this.submitting = true;
      this.$store
        .dispatch("offers/POST_REQUEST", {
          endpoint: "create",
          params: offer
        })
        .then(() => {
          this.triggerActionOutcomeAlert("create", true);
          this.$router.push({ path: "/manage/offers" });
        })
        .catch(() => {
          this.triggerActionOutcomeAlert("create", false);
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
